import React, { useState, useEffect, useRef } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';

const YearWheel = ({ onChange, startYear = 1950, endYear = 2024, initialYear, style, position, VISIBLE_ITEMS = 5 }) => {
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );
  
  const getYearIndex = (year) => years.findIndex(y => y === year);
  const initialIndex = getYearIndex(initialYear || startYear);
  
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const [floatingSelectedIndex, setFloatingSelectedIndex] = useState(initialIndex);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [offset, setOffset] = useState(0);
  const containerRef = useRef(null);
  const lastIndexRef = useRef(selectedIndex);
  const accumulatedScrollRef = useRef(0);
  
  const ITEM_HEIGHT = 40;
  //const VISIBLE_ITEMS = 3;
  const SCROLL_SENSITIVITY = 0.002;

  const jumpDecade = (direction) => {
    const currentYear = years[selectedIndex];
    const targetYear = currentYear + (direction * 10);
    const targetIndex = getYearIndex(Math.min(Math.max(startYear, targetYear), endYear));
    setIndexWithinBounds(targetIndex, true);
  };

  const setIndexWithinBounds = (index, shouldSnap = true) => {
    if (shouldSnap) {
      const boundedFloatingIndex = Math.max(0, Math.min(years.length - 1, index));
      setFloatingSelectedIndex(boundedFloatingIndex);
      const roundedIndex = Math.round(index);
      const boundedIndex = Math.max(0, Math.min(years.length - 1, roundedIndex));
      setSelectedIndex(boundedIndex);
      setOffset(0);
      onChange(years[boundedIndex]);
    } else {
      const boundedIndex = Math.max(0, Math.min(years.length - 1, index));
      setFloatingSelectedIndex(boundedIndex);
      setSelectedIndex(Math.floor(boundedIndex));
      setOffset(boundedIndex % 1);
    }
  };

  useEffect(() => {
    onChange(years[initialIndex]);
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartY(e.clientY);
    lastIndexRef.current = selectedIndex + offset;
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    
    const deltaY = e.clientY - startY;
    const indexDelta = deltaY / ITEM_HEIGHT;
    const newIndex = lastIndexRef.current - indexDelta;
    setIndexWithinBounds(newIndex, false);
  };

  const handleMouseUp = (e) => {
    if (!isDragging) return;
    setIsDragging(false);
      
    const deltaY = e.clientY - startY;
    const indexDelta = deltaY / ITEM_HEIGHT;
    const newIndex = lastIndexRef.current - indexDelta;
    setIndexWithinBounds(newIndex, true);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartY(e.touches[0].clientY);
    lastIndexRef.current = selectedIndex + offset;
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    
    const deltaY = e.touches[0].clientY - startY;
    const indexDelta = deltaY / ITEM_HEIGHT;
    const newIndex = lastIndexRef.current - indexDelta;
    setIndexWithinBounds(newIndex, false);
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    setIsDragging(false);
    setIndexWithinBounds(selectedIndex + offset, true);
  };

  const handleWheel = (e) => {
    e.preventDefault();
    
    // Accumulate scroll movement
    const move = e.deltaY * SCROLL_SENSITIVITY;
    accumulatedScrollRef.current += move;
      
      if (Math.abs(move) > 0.5) {
          const deltaY = move;
          const indexDelta = deltaY / ITEM_HEIGHT;
          const newIndex = lastIndexRef.current - indexDelta;
          setIndexWithinBounds(newIndex, false);
      }
      else {
          
          // Get the whole number of units moved
          let unitsToMove = 0;
          if (accumulatedScrollRef.current > 0) unitsToMove = Math.trunc(Math.sqrt(accumulatedScrollRef.current));
          else unitsToMove = Math.trunc(-Math.sqrt(-accumulatedScrollRef.current));
          //console.log(unitsToMove, accumulatedScrollRef.current, e.deltaY);
          if (unitsToMove !== 0 && Math.abs(move) > 0.025) {
              // Keep the remainder
              accumulatedScrollRef.current = 0;
              
              // Calculate new position
              const currentPosition = selectedIndex + offset;
              const newPosition = currentPosition + unitsToMove;
              
              // Update position
              setIndexWithinBounds(newPosition, true);
          }
      }
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

const baseStyles = {
    width: '60px',
    height: `${ITEM_HEIGHT * VISIBLE_ITEMS}px`,
    overflow: 'hidden',
    position: 'relative',
    background: 'linear-gradient(145deg, #353535, #1b1b1b)',
    borderRadius: '8px',
    boxShadow: 'inset 0 2px 5px rgba(120,120,120,0.4), inset 0 -2px 5px rgba(0,0,0,0.4)',
    cursor: 'grab',
    perspective: '1000px'
  };

  const containerStyle = {
    ...style,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '6px',
  };

  const buttonStyle = {
    width: '55px',
    height: '24px',
    background: '#111',
    border: '1px solid rgba(255, 240, 220, 0.2)',
    borderRadius: '6px',
    color: 'rgb(255, 240, 220)',
    opacity: 0.85,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2px',
    fontSize: '10px',
    fontFamily: '"Courier New", monospace',
    transition: 'all 0.2s ease',
    padding: '5px',
    boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.5)',
  };

  return (
    <div style={containerStyle}>
      <button
        onClick={() => jumpDecade(-1)}
        disabled={years[selectedIndex] - 10 < startYear}
        style={buttonStyle}
        className="hover:bg-[#2a2a2a] hover:border-[rgba(255,240,220,0.3)] disabled:opacity-30 disabled:cursor-not-allowed"
      >
        <span>-10y</span>
        <ChevronUp className="w-3 h-3" />
      </button>

      <div className="year-wheel" style={baseStyles}>
        {/* [Keep existing year wheel inner content] */}
        <div
          className="picker-gradient-top"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: ITEM_HEIGHT * (VISIBLE_ITEMS / 5 * 2),
            background: 'linear-gradient(to bottom, #1a1a1a 5%, transparent)',
            zIndex: 2,
            pointerEvents: 'none'
          }}
        />
        <div
          className="picker-gradient-bottom"
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: ITEM_HEIGHT * (VISIBLE_ITEMS / 5 * 2),
            background: 'linear-gradient(to top, #1a1a1a 5%, transparent)',
            zIndex: 2,
            pointerEvents: 'none'
          }}
        />
        <div
          className="picker-selection-indicator"
          style={{
            position: 'absolute',
            left: '0',
            right: '0',
            top: '49%',
            height: ITEM_HEIGHT,
            transform: 'translateY(-50%)',
            background: 'linear-gradient(90deg, rgba(255, 0, 0,0.1) 0%, rgba(255, 0, 0,0.2) 50%, rgba(255, 0, 0,0.1) 100%)',
            borderTop: '1px solid rgba(255, 255, 255, 0.3)',
            borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
            boxShadow: '0 0 15px rgba(216, 201, 155, 0.1)',
            zIndex: 1,
            pointerEvents: 'none'
          }}
        />
        <div
          ref={containerRef}
          className="picker-items"
          style={{
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            transform: `translateY(-50%) translateY(${(-selectedIndex - offset) * ITEM_HEIGHT + 1480}px)`,
            transition: isDragging ? 'none' : 'transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)',
            transformStyle: 'preserve-3d'
          }}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onWheel={handleWheel}
        >
          {years.map((year, index) => {
            const distance = Math.abs(index - selectedIndex - offset);
            const rotateX = (index - selectedIndex - offset) * 12;
            return (
              <div
                key={year}
                className="picker-item"
                style={{
                  height: ITEM_HEIGHT,
                  lineHeight: `${ITEM_HEIGHT}px`,
                  textAlign: 'center',
                  color: 'rgb(216, 201, 155)',
                  opacity: Math.max(0.2, 1 - distance * 0.15),
                  fontSize: '12px',
                  fontFamily: '"DIN Condensed", "Roboto Condensed", "Arial Narrow", sans-serif',
                  textShadow: distance < 1 ? '0 0 10px rgba(216, 201, 155, 0.3), 0 0 20px rgba(216, 201, 155, 0.2)' : 'none',
                  transform: `
                    scale(${2 - Math.abs(floatingSelectedIndex - index) * 0.1})
                    translateZ(${-Math.abs(rotateX) * 0.5}px)
                    rotateX(${rotateX}deg)
                  `,
                  transition: isDragging ? 'none' : 'all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)',
                  userSelect: 'none'
                }}
              >
                {year}
              </div>
            )}
          )}
        </div>
      </div>
      <button
        onClick={() => jumpDecade(1)}
        disabled={years[selectedIndex] + 10 > endYear}
        style={buttonStyle}
        className="hover:bg-[#2a2a2a] hover:border-[rgba(255,240,220,0.3)] disabled:opacity-30 disabled:cursor-not-allowed"
      >
        <ChevronDown className="w-3 h-3" />
        <span>+10y</span>
      </button>
    </div>
  );
};

export default YearWheel;
