import React, { useState, useEffect, useRef } from 'react';
import CassettePage from './CassettePage';
import ResultsPage from './ResultsPage';
import { AccountStatus, AuthModal } from './UserComponents';
import './App.css';

const BACKEND = "https://emergesound.ai/api";

const VideoBackground = ({ isPlaying, onVideoEnd, style = {} }) => {
  const videoRef = useRef(null);
  const PLAYBACK_SPEED = 0.3;

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.playbackRate = PLAYBACK_SPEED;
      
      if (isPlaying) {
        videoRef.current.currentTime = 0;
        videoRef.current.play().catch(err => console.error('Video autoplay failed:', err));
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  return (
    <video
      ref={videoRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 100,
        ...style
      }}
      playsInline
      muted
      onEnded={onVideoEnd}
    >
      <source src="/background_video.mp4" type="video/mp4" />
    </video>
  );
};

function App() {
  const [currentPage, setCurrentPage] = useState('cassette');
  const [initialData, setInitialData] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  
  // Auth state
  const [user, setUser] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [session, setSession] = useState(null);
  const [credits, setCredits] = useState(5);

  // Add preloading effect
  useEffect(() => {
    // Preload video when page loads
    const preloadVideo = () => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = '/background_video.mp4';
      link.as = 'video';
      link.type = 'video/mp4';
      document.head.appendChild(link);
    };

    preloadVideo();
  }, []);
    
  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return fetchAnonymousCredits();
    }

    try {
      const response = await fetch(`${BACKEND}/auth/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();
      setUser(userData);
      setCredits(userData.credits_remaining);
      return userData;
    } catch (error) {
      console.error('Error fetching user:', error);
      localStorage.removeItem('token');
      return fetchAnonymousCredits();
    }
  };
    
  useEffect(() => {
    fetchUserData();
    
    // Check credits every minute
    const interval = setInterval(fetchUserData, 60000);
    return () => clearInterval(interval);
  }, []);

  const consumeCredit = async () => {
    const token = localStorage.getItem('token');
    const fingerprint = localStorage.getItem('deviceFingerprint');
  
    try {
      const headers = {
        'Content-Type': 'application/json'
      };
  
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
  
      const body = token ? {} : { fingerprint };
  
      const response = await fetch(`${BACKEND}/auth/credits/consume`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });
  
      if (!response.ok) {
        if (response.status === 402) {
          setShowAuthModal(true);
          return false;
        }
        const errorData = await response.json();
        console.error('Credit consumption failed:', errorData.detail);
        return false;
      }
  
      const data = await response.json();
      setCredits(data.credits_remaining);
      return true;
    } catch (error) {
      console.error('Error consuming credit:', error);
      return false;
    }
  };
  
  const fetchAnonymousCredits = async () => {
    try {
      const fingerprint = localStorage.getItem('deviceFingerprint') ||
        (() => {
          const newFingerprint = crypto.randomUUID();
          localStorage.setItem('deviceFingerprint', newFingerprint);
          return newFingerprint;
        })();
  
      const response = await fetch(`${BACKEND}/auth/credits?fingerprint=${fingerprint}`);
      if (!response.ok) {
        throw new Error('Failed to fetch credits');
      }
  
      const data = await response.json();
      setCredits(data.credits_remaining);
      return data;
    } catch (error) {
      console.error('Error fetching credits:', error);
      setCredits(0);
      return null;
      return null;
    }
  };

    const handleSubmit = async (data) => {
      //if (await consumeCredit()) {
        setIsError(false);
        setInitialData(data);
        setIsTransitioning(true);
        setIsVideoPlaying(true);
        setTimeout(() => {
          setCurrentPage('results');
          setIsTransitioning(false);
        }, 500);
      //}
    };
    
    const handleVideoEnd = () => {
      setIsVideoPlaying(false);
    };

  const handleBack = () => {
    setIsTransitioning(true);
    setInitialData(null);
    setIsError(false);
    setIsTransitioning(false);
    setTimeout(() => {
      setCurrentPage('cassette');
    }, 500);
  };

  // Handle background image loading
  useEffect(() => {
    const img = new Image();
    const timeoutId = setTimeout(() => {
      img.src = currentPage === 'cassette' ? '/background.jpg' : '/background2.jpg';
      img.onload = () => setBackgroundImage(img.src);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [currentPage]);
    
    return (
      <div
        className={`App ${isTransitioning ? 'transitioning' : ''}`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {/* Video Background */}
        {isVideoPlaying && (
          <VideoBackground
            isPlaying={isVideoPlaying}
            onVideoEnd={handleVideoEnd}
            style={{
              objectPosition: currentPage === 'cassette' ? 'center' : 'center'
            }}
          />
        )}

        {/* Auth Components */}
        <AccountStatus
          user={session?.user}
          credits={credits}
          onLogin={() => setShowAuthModal(true)}
          onLogout={async () => {
            setSession(null)
            setCredits(5)
          }}
        />
        
        <AuthModal
          isOpen={showAuthModal}
          onClose={() => setShowAuthModal(false)}
        />
      
        {/* Page Components */}
        {currentPage === 'cassette' && (
          <CassettePage
            onSubmit={handleSubmit}
            isTransitioning={isTransitioning}
            isError={isError}
            setIsError={setIsError}
            consumeCredit={consumeCredit}
            credits={credits}
          />
        )}
        
        {currentPage === 'results' && (
          <ResultsPage
            initialData={initialData}
            onBack={handleBack}
            consumeCredit={consumeCredit}
            session={session}
            setIsVideoPlaying={setIsVideoPlaying}
          />
        )}
        
        <div className="vhs-overlay"></div>
        <div className="noise"></div>
      </div>
    );
}

export default App;
