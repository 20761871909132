import React, { useState, useRef, useEffect } from 'react';
import './CassettePage.css';
import YearWheel from './YearWheel';
import { useResponsiveLayout } from './useResponsiveLayout';
import { X } from 'lucide-react';
import { Upload, Play, Pause } from 'lucide-react';

const BACKEND = "https://emergesound.ai/api";

const AudioUploadModal = ({ isOpen, onClose, onFileSelect, credits }) => {
  const [file, setFile] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(30);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('audio/')) {
      setFile(selectedFile);
      const url = URL.createObjectURL(selectedFile);
      if (audioRef.current) {
        audioRef.current.src = url;
        // Reset selection when new file is loaded
        setStartTime(0);
        setEndTime(30);
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
      // Initialize end time to either 30 seconds or file duration if shorter
      setEndTime(Math.min(30, audioRef.current.duration));
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.currentTime = startTime;
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      if (audioRef.current.currentTime >= endTime) {
        audioRef.current.pause();
        audioRef.current.currentTime = startTime;
        setIsPlaying(false);
      }
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleStartTimeChange = (e) => {
    const newStart = parseFloat(e.target.value);
    // Ensure start time doesn't exceed end time - 1 second
    if (newStart < endTime - 1) {
      setStartTime(newStart);
      if (audioRef.current && isPlaying) {
        audioRef.current.currentTime = newStart;
      }
    }
  };

  const handleEndTimeChange = (e) => {
    const newEnd = parseFloat(e.target.value);
    // Ensure end time isn't less than start time + 1 second and doesn't exceed start time + 30 seconds
    if (newEnd > startTime + 1 && newEnd <= startTime + 30) {
      setEndTime(newEnd);
    }
  };

  const handleSubmit = () => {
    if (file) {
      onFileSelect({
        file,
        startTime,
        endTime,
        fileName: file.name
      });
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <button className="auth-close" onClick={onClose}>
          <X size={20} />
        </button>

        <div className="auth-modal-header">
          <h2 className="auth-modal-title">Upload Audio Track</h2>
          <div className="auth-modal-subtitle">
            Select an audio file and choose up to a 30-second segment.  Currently we only support wav files
          </div>
        </div>
        
        <div className="bg-black/20 rounded-lg p-4 border border-amber-200/20 mb-4">
          <span className="text-amber-200">
            Uploading and analyzing a track will cost 3 credits. You currently have {credits} credits.
          </span>
        </div>

        {!file ? (
          <div className="border-2 border-dashed border-amber-200/20 rounded-lg p-8 text-center">
            <input
              ref={fileInputRef}
              type="file"
              accept="audio/wav,audio/x-wav"
              onChange={handleFileChange}
              className="hidden"
            />
          </div>
        ) : (
          <div className="space-y-4">
            <div className="text-amber-200 truncate">{file.name}</div>
            
            <div className="bg-black/20 rounded-lg p-4 border border-amber-200/20">
              <div className="flex items-center gap-4 mb-4">
                <button
                  onClick={handlePlayPause}
                  className="p-2 rounded-full bg-amber-200/10 hover:bg-amber-200/20 text-amber-200"
                >
                  {isPlaying ? <Pause size={20} /> : <Play size={20} />}
                </button>
                
                <div className="text-amber-200/60 text-sm">
                  Selected: {formatTime(endTime - startTime)}
                </div>
              </div>

              <div className="space-y-4">
                <div className="space-y-2">
                  <div className="flex justify-between text-sm text-amber-200/60">
                    <span>Start: {formatTime(startTime)}</span>
                  </div>
                  <input
                    type="range"
                    min={0}
                    max={Math.max(0, duration - 1)}
                    value={startTime}
                    onChange={handleStartTimeChange}
                    className="w-full accent-amber-200"
                  />
                </div>

                <div className="space-y-2">
                  <div className="flex justify-between text-sm text-amber-200/60">
                    <span>End: {formatTime(endTime)}</span>
                  </div>
                  <input
                    type="range"
                    min={0}
                    max={duration}
                    value={endTime}
                    onChange={handleEndTimeChange}
                    className="w-full accent-amber-200"
                  />
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              className="auth-submit"
            >
              Use Selected Portion
            </button>
          </div>
        )}

        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={() => setIsPlaying(false)}
          className="hidden"
        />
      </div>
    </div>
  );
};

const CassettePage = ({ onSubmit, isTransitioning, isError, setIsError, consumeCredit, credits }) => {
  const [selectedSong, setSelectedSong] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [startYear, setStartYear] = useState(1950);
  const [endYear, setEndYear] = useState(2024);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({ id: 'popular', streamThreshold: 1000000 });
  const [showHint, setShowHint] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const searchTimeoutRef = useRef(null);
  const searchContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleFileSelect = (fileData) => {
      setSelectedFile(fileData);
      setSelectedSong(null);
      setSearchTerm(fileData.fileName);
      setSearchResults([]);
  };
    
  const { interpolatePosition } = useResponsiveLayout(0.5, 2.5);

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Check if hint has been dismissed before
  useEffect(() => {
    const deviceId = localStorage.getItem('deviceFingerprint');
    const hintDismissed = localStorage.getItem(`hintDismissed_${deviceId}`);
    
    if (!hintDismissed) {
      const timeoutId = setTimeout(() => {
        setShowHint(true);
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  const dismissHint = () => {
    const deviceId = localStorage.getItem('deviceFingerprint');
    localStorage.setItem(`hintDismissed_${deviceId}`, 'true');
    setShowHint(false);
  };
    
  // Desktop positions
  const positions = {
    searchContainer: {
      portrait: { x: 48, y: 22, scale: 2.2 },
      natural: { x: 49.5, y: 22, scale: 0.76 },
      landscape: { x: 49.5, y: 10, scale: 1 }
    },
    fileUpload: {
      portrait: { x: 50, y: 38, scale: 1 },
      natural: { x: 50, y: 43, scale: 1 },
      landscape: { x: 50, y: 48, scale: 1 }
    },
    submitButton: {
      portrait: { x: 50, y: 60, scale: 1 },
      natural: { x: 49.5, y: 60, scale: 1 },
      landscape: { x: 50, y: 62, scale: 1 }
    },
    yearWheelLeft: {
      portrait: { x: -9, y: 40, scale: 6.5 },
      natural: { x: 30, y: 40, scale: 2 },
      landscape: { x: 30, y: 36, scale: 2.5 }
    },
    yearWheelRight: {
      portrait: { x: 108, y: 40, scale: 6.5 },
      natural: { x: 69, y: 40, scale: 2 },
      landscape: { x: 69, y: 36, scale: 2.5 }
    }
  };

  const searchPos = interpolatePosition(
    positions.searchContainer.portrait,
    positions.searchContainer.natural,
    positions.searchContainer.landscape
  );

  const filePos = interpolatePosition(
    positions.fileUpload.portrait,
    positions.fileUpload.natural,
    positions.fileUpload.landscape
  );

  const submitPos = interpolatePosition(
    positions.submitButton.portrait,
    positions.submitButton.natural,
    positions.submitButton.landscape
  );

  const leftWheelPos = interpolatePosition(
    positions.yearWheelLeft.portrait,
    positions.yearWheelLeft.natural,
    positions.yearWheelLeft.landscape
  );

  const rightWheelPos = interpolatePosition(
    positions.yearWheelRight.portrait,
    positions.yearWheelRight.natural,
    positions.yearWheelRight.landscape
  );

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    if (value.trim()) {
      searchTimeoutRef.current = setTimeout(async () => {
        try {
          const response = await fetch(`${BACKEND}/search`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: value,
              limit: 25
            })
          });

          if (!response.ok) {
            throw new Error(await response.text());
          }

          const data = await response.json();
          setSearchResults(data);
        } catch (err) {
          console.error('Search failed:', err);
        }
      }, 50);
    } else {
      setSearchResults([]);
    }
  };

  const handleTrackSelect = (song) => {
    setSelectedSong(song);
    setSearchTerm(song.title);
    setSearchResults([]);
    setSelectedFile(null);
  };

    const handleSubmit = async () => {
        setIsError(false);
        
        try {
            if (selectedFile) {
                // Instead of doing the upload here, just pass the file data up
                onSubmit({
                    uploadFile: {
                        file: selectedFile.file,
                        startTime: selectedFile.startTime,
                        endTime: selectedFile.endTime,
                        fileName: selectedFile.fileName
                    },
                    startYear,
                    endYear,
                    selectedFilter
                });
            } else if (selectedSong) {
                // Regular song search flow remains the same
                onSubmit({
                    song: selectedSong,
                    startYear,
                    endYear,
                    selectedFilter
                });
            }
        } catch (error) {
            console.error('Error during submission:', error);
            setIsError(true);
        }
    };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (isMobile) {
    return (
      <div className={`cassette-page mobile ${isTransitioning ? 'fade-out' : ''}`}>
        <div className="mobile-content">
          <div className="search-section">
            <div ref={searchContainerRef} className="mobile-search-container">
              <div className="search-wrapper">
                {selectedSong && (
                  <div className="selected-song-artwork">
                    <img
                      src={selectedSong.artwork_url}
                      alt={selectedSong.title}
                      className="artwork-preview"
                    />
                  </div>
                )}
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search for a song..."
                  className="search-input"
                />
              </div>
              {searchResults.length > 0 && (
                <div className="mobile-search-results">
                  {searchResults.map((song) => (
                    <div
                      key={song.id}
                      className="search-result-item"
                      onClick={() => handleTrackSelect(song)}
                    >
                      <img
                        src={song.artwork_url}
                        alt={song.title}
                        className="search-result-artwork"
                      />
                      <div className="search-result-info">
                        <div className="search-result-title">{song.title}</div>
                        <div className="search-result-artist">{song.artist}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="mobile-year-wheel-section">
            <div className="year-wheel-explanation">
              Use these dials to select the era of music you would like to search for simlar tracks in
            </div>
            <div className="mobile-year-wheels">
              <div className="year-wheel-container">
                <div className="year-wheel-label">Starting Year</div>
                <YearWheel
                  position="left"
                  onChange={setStartYear}
                  startYear={1950}
                  endYear={2024}
                  initialYear={1950}
                  VISIBLE_ITEMS={2.5}
                />
              </div>
              <div className="year-wheel-container">
                <div className="year-wheel-label">Ending Year</div>
                <YearWheel
                  position="right"
                  onChange={setEndYear}
                  startYear={1950}
                  endYear={2024}
                  initialYear={2024}
                  VISIBLE_ITEMS={2.5}
                />
              </div>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className="mobile-submit-button"
            disabled={!selectedSong && !selectedFile}
          >
            Submit
          </button>
        </div>

        {showHint && (
          <div className="mobile-hint-container">
            <div className="hint-box">
              <button
                className="hint-close"
                onClick={dismissHint}
                aria-label="Close hint"
              >
                <X size={18} />
              </button>
              <div className="hint-header">
                ▸ Not sure what to do?
              </div>
              <div className="hint-text">
                Type a song name in the search bar above. After selecting your song,
                use the year wheels below to set your desired time period.
                Press submit when you're ready to search and our algorithm will find 
                similar songs from that era.
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  // Desktop layout remains unchanged
  return (
    <div className={`cassette-page ${isTransitioning ? 'fade-out' : ''}`}>
      <YearWheel
        position="left"
        onChange={setStartYear}
        startYear={1950}
        endYear={2024}
        initialYear={1950}
        style={{
          position: 'absolute',
          left: `${leftWheelPos.x}%`,
          top: `${leftWheelPos.y}%`,
          transform: `translate(-50%, -50%) scale(${leftWheelPos.scale})`,
          transformOrigin: 'center center'
        }}
      />
      
        <div
          ref={searchContainerRef}
          className="search-container"
          style={{
            position: 'absolute',
            left: `${searchPos.x}%`,
            top: `${searchPos.y}%`,
            transform: `translate(-50%, -50%)`,
            width: `${searchPos.scale * 1000}px`
          }}
        >
          <div className="search-wrapper">
            {selectedSong && (
              <div className="selected-song-artwork">
                <img
                  src={selectedSong.artwork_url}
                  alt={selectedSong.title}
                  className="artwork-preview"
                />
              </div>
            )}
            {selectedFile && (
              <div className="selected-song-artwork">
                <div className="file-preview">
                  <Upload size={24} />
                </div>
              </div>
            )}
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search for a song..."
              className="search-input"
            />
            <button
              onClick={() => setIsUploadModalOpen(true)}
              className="upload-button"
              title="Upload audio file"
            >
              <Upload size={20} />
            </button>
          </div>
        {searchResults.length > 0 && (
          <div className="search-results">
            {searchResults.map((song) => (
              <div
                key={song.id}
                className="search-result-item"
                onClick={() => handleTrackSelect(song)}
              >
                <img
                  src={song.artwork_url}
                  alt={song.title}
                  className="search-result-artwork"
                />
                <div className="search-result-info">
                  <div className="search-result-title">{song.title}</div>
                  <div className="search-result-artist">{song.artist}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
          
          <AudioUploadModal
                  isOpen={isUploadModalOpen}
                  onClose={() => setIsUploadModalOpen(false)}
                  onFileSelect={handleFileSelect}
                  credits={credits}
                />

      <div
        className="file-upload-container"
        style={{
          position: 'absolute',
          left: `${filePos.x}%`,
          top: `${filePos.y}%`,
          transform: 'translate(-50%, -50%)'
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          accept="audio/*"
          className="file-input"
          id="audio-file-input"
        />
      </div>

      <YearWheel
        position="right"
        onChange={setEndYear}
        startYear={1950}
        endYear={2024}
        initialYear={2024}
        style={{
          position: 'absolute',
          left: `${rightWheelPos.x}%`,
          top: `${rightWheelPos.y}%`,
          transform: `translate(-50%, -50%) scale(${rightWheelPos.scale})`,
          transformOrigin: 'center center'
        }}
      />

      <button
        onClick={handleSubmit}
        className="submit-button"
        disabled={!selectedSong && !selectedFile}
        style={{
          position: 'absolute',
          left: `${submitPos.x}%`,
          top: `${submitPos.y}%`,
          transform: 'translate(-50%, -50%)'
        }}
      >
        Submit
      </button>
      
      {showHint && (
        <div className="hint-container">
          <div className="hint-box">
            <button
              className="hint-close"
              onClick={dismissHint}
              aria-label="Close hint"
            >
              <X size={18} />
            </button>
            <div className="hint-header">
              ▸ Not sure what to do?
            </div>
            <div className="hint-text">
              Type a song name in the search bar above. After selecting your song,
              use the year wheels on each side to set your desired time period.
              Press submit when you're ready to search and our algorithm will find 
              similar songs from that era.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CassettePage;
