import React, { useState, useEffect, useRef } from 'react';
import { LogIn, LogOut, CreditCard, ChevronDown, Wallet, Settings, X, Mail, ArrowLeft, AlertCircle } from 'lucide-react';

const BACKEND = "https://emergesound.ai/api";

const SubscriptionModal = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubscribe = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const token = localStorage.getItem('token');
      const response = await fetch(`${BACKEND}/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { url } = await response.json();
      window.location.href = url;
      
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <button className="auth-close" onClick={onClose}>
          <X size={20} />
        </button>

        <div className="auth-modal-header">
          <h2 className="auth-modal-title">Upgrade to Premium</h2>
          <p className="auth-modal-subtitle">
            Get 50 credits per day and support the development
          </p>
        </div>

        <div className="space-y-6">
          <div className="bg-black/20 rounded-lg p-4 border border-amber-200/20">
            <h3 className="text-lg mb-2 text-amber-200">Premium Features</h3>
            <ul className="space-y-2 text-amber-200/80">
              <li>50 credits daily</li>
              <li>Credits reset every day at midnight</li>
              <li>Cancel anytime</li>
            </ul>
          </div>

          <div className="text-center text-2xl font-bold text-amber-200">
            £10/month
          </div>

          {error && (
            <div className="auth-error-message">
              <AlertCircle size={16} />
              <span>{error}</span>
            </div>
          )}

          <button
            onClick={handleSubscribe}
            disabled={loading}
            className="auth-submit"
          >
            {loading ? 'Processing...' : 'Subscribe Now'}
          </button>

          <p className="text-center text-sm text-amber-200/60">
            Secure payment powered by Stripe
          </p>
        </div>
      </div>
    </div>
  );
};

export const AccountStatus = ({ onLogin, onLogout }) => {
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const dropdownRef = useRef(null);

  const fetchCredits = async () => {
    try {
      const token = localStorage.getItem('token');
      const fingerprint = localStorage.getItem('deviceFingerprint');
      
      const response = await fetch(
        `${BACKEND}/auth/credits${fingerprint ? `?fingerprint=${fingerprint}` : ''}`,
        {
          headers: token ? {
            'Authorization': `Bearer ${token}`
          } : {}
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCredits(data.credits_remaining);
      }
    } catch (error) {
      console.error('Error fetching credits:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch(`${BACKEND}/auth/me`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        } else {
          localStorage.removeItem('token');
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        localStorage.removeItem('token');
        setUser(null);
      }
    };

    fetchUser();
    fetchCredits();
    
    const interval = setInterval(fetchCredits, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setCredits(5);
    setIsDropdownOpen(false);
    onLogout?.();
  };

  const handleUpgradeClick = () => {
    setShowSubscriptionModal(true);
    setIsDropdownOpen(false);
  };

  if (loading) return null;

  return (
    <>
      <div className="account-status" ref={dropdownRef}>
        <div className="auth-buttons">
          <div className="credits-display">
            <CreditCard size={16} />
            <span>{credits} credits</span>
          </div>
          
          {user ? (
            <div className="auth-user">
              <button
                className="auth-button auth-email"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <span>{user.email}</span>
                <ChevronDown
                  size={16}
                  className={`transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                />
              </button>

              {isDropdownOpen && (
                <div className="auth-modal">
                  <div className="auth-user-email mb-4 w-full text-center">
                    {user.account_type === 'PREMIUM' ? 'Premium Account' : 'Free Account'}
                  </div>

                  {user.account_type !== 'PREMIUM' && (
                    <button
                      className="auth-option mb-2"
                      style={{ color: 'rgb(255, 240, 220)' }}
                      onClick={handleUpgradeClick}
                    >
                      <Wallet size={16} />
                      <span>Upgrade to Premium</span>
                    </button>
                  )}

                <button className="auth-option mb-2" style={{ color: 'rgb(255, 240, 220)' }}>
                    <Settings size={16} />
                    <span>Account Settings</span>
                  </button>

                  <button
                    className="auth-option auth-logout"
                    style={{ color: 'rgb(255, 240, 220)' }}
                    onClick={handleLogout}
                  >
                    <LogOut size={16} />
                    <span>Sign Out</span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <button className="auth-button" onClick={onLogin}>
              <LogIn size={16} />
              <span>Sign In</span>
            </button>
          )}
        </div>
      </div>

      <SubscriptionModal
        isOpen={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
      />
    </>
  );
};

export const AuthModal = ({ isOpen, onClose }) => {
  const [mode, setMode] = useState('initial');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setMode('initial');
      setError('');
      setEmail('');
      setPassword('');
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) return;
      
      if (event.data.token) {
        localStorage.setItem('token', event.data.token);
        window.location.reload();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  if (!isOpen) return null;

    const handleGoogleLogin = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const popup = window.open(
          `${BACKEND}/auth/google/authorize`,
          'Google Login',
          `width=600,height=600,left=${(window.screen.width/2)-300},top=${(window.screen.height/2)-300}`
        );

        if (!popup) {
          throw new Error('Popup blocked. Please enable popups for this site.');
        }

        const receiveMessage = (event) => {
          if (event.data.token) {
            // Clean up
            window.removeEventListener('message', receiveMessage);
            if (!popup.closed) popup.close();
            
            // Handle login
            localStorage.setItem('token', event.data.token);
            window.location.reload();
          }
          if (event.data.error) {
            setError(event.data.error);
            window.removeEventListener('message', receiveMessage);
            if (!popup.closed) popup.close();
          }
        };

        window.addEventListener('message', receiveMessage);

        // Check if popup closed
        const checkClosed = setInterval(() => {
          if (popup.closed) {
            clearInterval(checkClosed);
            window.removeEventListener('message', receiveMessage);
            setLoading(false);
            if (!localStorage.getItem('token')) {
              setError('Login cancelled');
            }
          }
        }, 1000);

      } catch (err) {
        setError(err.message || 'Failed to connect with Google');
        setLoading(false);
      }
    };

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const endpoint = mode === 'email' ? '/auth/token' : '/auth/register';
      const fingerprint = localStorage.getItem('deviceFingerprint');

      const response = await fetch(`${BACKEND}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          fingerprint: mode === 'register' ? fingerprint : undefined
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || 'Authentication failed');
      }

      if (data.access_token) {
        localStorage.setItem('token', data.access_token);
        window.location.reload();
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderEmailForm = () => (
    <div className="space-y-4">
      <button
        className="auth-back"
        onClick={() => {
          setMode('initial');
          setError('');
        }}
      >
        <ArrowLeft size={20} />
      </button>

      <h2 className="auth-modal-title">
        {mode === 'email' ? 'Sign In' : 'Create Account'}
      </h2>

      {error && (
        <div className="auth-error-message">
          <AlertCircle size={16} />
          <span>{error}</span>
        </div>
      )}

      <form onSubmit={handleEmailAuth} className="auth-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="auth-input"
          required
        />
        
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="auth-input"
          required
        />
        
        <button
          type="submit"
          className="auth-submit"
          disabled={loading}
        >
          {loading ? 'Please wait...' : (mode === 'email' ? 'Sign In' : 'Create Account')}
        </button>

        <button
          type="button"
          className="auth-toggle"
          onClick={() => {
            setMode(mode === 'email' ? 'register' : 'email');
            setError('');
          }}
        >
          {mode === 'email' ? "Don't have an account?" : 'Already have an account?'}
        </button>
      </form>
    </div>
  );

  const renderInitial = () => (
    <>
      <button className="auth-close" onClick={onClose}>
        <X size={20} />
      </button>
      
      <div className="auth-modal-header">
        <h2 className="auth-modal-title">Get More Credits</h2>
        <p className="auth-modal-subtitle">
          Create an account to get 10 free credits per day
        </p>
      </div>
      
      <div className="space-y-4">
        <button
          className="auth-option w-full"
          onClick={handleGoogleLogin}
          style={{ color: 'rgb(255, 240, 220)' }}
        >
          <span>Continue with Google</span>
        </button>
        
        <div className="auth-divider">
          <span>or</span>
        </div>
        
        <button
          className="auth-option w-full"
          onClick={() => setMode('email')}
          style={{ color: 'rgb(255, 240, 220)' }}
        >
          <Mail size={20} />
          <span>Continue with Email</span>
        </button>
      </div>
    </>
  );

  return (
    <div className="auth-modal-overlay" onClick={onClose}>
      <div className="auth-modal" onClick={e => e.stopPropagation()}>
        {mode === 'initial' ? renderInitial() : renderEmailForm()}
      </div>
    </div>
  );
};

export default AccountStatus;
